import React from "react";
import momentTimezone from 'moment-timezone';

interface IProps {
    onChange: (dateTime: string, arrivalTime: number) => void,
    value: any
    isMobile?: boolean
}

interface IState {
}

export default class ArrivalTime extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div>
                <label htmlFor={`job-arrival-time`} className={this.props.isMobile ? 'label form-label' : "form-label"}>Arrival time</label>
                <input onChange={(el) => {
                    console.log(el.target.value)
                    const date = momentTimezone(new Date(el.target.value)).tz('Europe/Kiev');
                  console.log(date)

                    this.props.onChange(
                        date.format('YYYY-MM-DDTHH:mm'),
                        parseInt(date.format('x'), 10)
                    );
                }}
                       value={this.props.value}
                       type="datetime-local"
                       className={this.props.isMobile ? 'input' : "form-control"}
                       id={`job-arrival-time`}
                       aria-describedby={`job-arrival-time-help`}
                />
                <div id={`job-arrival-time-help`} className="form-text" />
            </div>
        );
    }
}
