import TypeStatus from '../store/interface/enums/TypeStatus';

export const getStatus = (status: TypeStatus | undefined): string => {
    switch (status) {
        case TypeStatus.CALL_WAITING:
            return 'Call waiting';
        case TypeStatus.REQUEST_IN_PROCESS:
            return 'Request in process';
        case TypeStatus.WAITING_FOR_PAYMENT:
            return 'Waiting for payment';
        case TypeStatus.CANCELED:
            return 'Canceled';
        case TypeStatus.WAITING_FOR_THE_MASTER:
            return 'Waiting for the master';
        case TypeStatus.MASTER_WORKS:
            return 'Master works';
        case TypeStatus.SUCCESSFULLY:
            return 'Successfully';
        default:
            return '';
    }
}
