import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import IconClose from '../icons/contract-statuses/close.svg';
import Button from './button';
import StoreAssistance from '../store/assistance';

interface IProps {
    onClose: () => void
    isVisible: boolean
    amount: number | string
    details: string
    onChangeAmount: (event: ChangeEvent<HTMLInputElement>) => void
    onChangeDetails: (event: ChangeEvent<HTMLInputElement>) => void
    confirmModal: () => Promise<void>
}

const FakeCallModal = (props: IProps) => {
    const { onClose, isVisible, amount, details, onChangeDetails, onChangeAmount, confirmModal } = props;
    const [currentPosition, setCurrentPosition] = useState<number>(100);

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
            setCurrentPosition(0);
        } else {
            document.body.style.overflow = 'auto';
            setCurrentPosition(100);
        }
    }, [isVisible]);

    const closeModal = (event: MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div style={{display: isVisible ? 'block' : 'none'}} onClick={closeModal} className={'backdrop'}>
            <div
                style={{
                    transform: `translate(0px, ${currentPosition}%)`,
                }}
                className={'modal-container'}
            >
                <div onClick={onClose} className={'icon-container align-end'}>
                    <img alt={'close'} src={IconClose} />
                </div>
                <div className={'header-modal'}>Fake request</div>
                <label htmlFor={'price'} className={'label form-label'}>Amount {StoreAssistance.CURRENCY}</label>
                <input className={'input'}
                       type="number"
                       id={'price'}
                       value={amount}
                       onChange={onChangeAmount}
                />
                <label htmlFor={'details'} className={'label form-label'}>Address or other information</label>
                <input className={'input'}
                       id={'details'}
                       value={details}
                       onChange={onChangeDetails}
                />
                <div style={{marginTop: 8}}>
                    <Button
                        className={'button'}
                        type={'danger'}
                        onHandleClick={confirmModal}
                    >Fake request</Button>
                </div>
            </div>
        </div>
    );
};

export default FakeCallModal;
