import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';

import StoreAssistance from '../../store/assistance';
import IJob from "../../store/interface/IJob";
import Works from "../../store/works";
import AddJob from "../add-job";
import Button from "../button";
import Details from "../details";
import RequestId from "../request-id";
import ArrivalTime from "../arrival-time";
import momentTimezone from "moment-timezone";
import { ServiceType } from "../../store/interface/enums";
import AddJobMobile from '../add-job-mobile';

interface IProps {
    isMobile?: boolean
}

interface IState {
    jobs: Array<IJob>,
    arrivalTime: number,
    details: string,
    works: Array<{ value: string, name: string }>
    dateTime: string,
    isLoader: boolean,
    applicationId: string,
}

@observer
export default class StepRequestInProcess extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const arrivalTime = StoreAssistance.request?.typeObject.request.arrivalTime;
        const dateArrival = new Date(Date.now() + 40 * 60000);
        let dateTime = '';

        if (arrivalTime) {
            dateTime = momentTimezone(arrivalTime, 'x').tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm:ss');
        } else {
            dateTime = dateArrival.toJSON().split('.')[0]
        }

        this.state = {
            jobs: [],
            details: StoreAssistance.request?.typeObject.request.details || '',
            arrivalTime: arrivalTime || dateArrival.getTime(),
            dateTime,
            works: Works,
            isLoader: false,
            applicationId: StoreAssistance.request?.typeObject.request.id || ''
        };
    }

    async Invoicing() {
        try {
            if (!this.state.jobs.length) {
                alert('need to add work')
                return
            }


            if (this.state.jobs.find((item) => !item.amount)) {
                alert('In the work you need to specify the price')
                return
            }

            if (!this.state.details) {
                alert('need to add a description')
                return
            }


            this.setState({isLoader: true});

            const jobs = cloneDeep(this.state.jobs)

            jobs.forEach((item, index) => {
                const amount = StoreAssistance.jobPriceTotal({
                    type: item.type as ServiceType,
                    amount: (item.amount || 0)
                })

                item.amount = index === 0 ? StoreAssistance.totalPrice({amount}) : amount;
            });

            const totalPay = jobs.reduce((result, item) => (result + (item.amount || 0)), 0)

            await StoreAssistance.Invoicing({
                arrivalTime: this.state.arrivalTime,
                details: this.state.details,
                jobs,
                totalPay,
                applicationId: this.state.applicationId
            });
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    async CancelRequest() {
        try {
            this.setState({isLoader: true});

            await StoreAssistance.CancellationBeforePaymentRequest(this.state.details);
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    onChangeDate = (dateTime: string, arrivalTime: number): void => {
        this.setState({dateTime, arrivalTime})
    };

    render() {
        const request = StoreAssistance.request?.typeObject.request;
        const {isMobile} = this.props;
        const { applicationId, dateTime, details, isLoader } = this.state;

        return (
            <>
                {isMobile ? (
                    <div>
                        <label htmlFor={'applicationId'} className={'label form-label'}>application number in your
                            system</label>
                        <input className={'input input-gray'}
                               id={'applicationId'}
                               value={applicationId}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({applicationId: event.target.value})}
                        />
                        <ArrivalTime
                            onChange={this.onChangeDate}
                            value={dateTime}
                            isMobile
                        />
                        <label htmlFor={'description'} className={'label form-label'}>Description</label>
                        <input className={'input'}
                               id={'description'}
                               value={details}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({details: event.target.value})}
                        />
                        <AddJobMobile updateJobList={(jobs: IJob[]) => this.setState({jobs})}/>
                        <Button
                            type={'primary'}
                            disable={isLoader}
                            onHandleClick={this.Invoicing.bind(this)}
                            className={'button'}
                        >Send Invoice</Button>
                        <Button
                            type={'danger'}
                            disable={isLoader}
                            className={'button'}
                            onHandleClick={this.CancelRequest.bind(this)}
                        >Cancel</Button>
                    </div>
                ) : (<>
                    <div>
                        <div className="row">
                            <div className="col-6 ps-1 pe-1">
                                <RequestId
                                    value={applicationId || request?.id || ''}
                                    onChange={(applicationId) => this.setState({applicationId})}
                                />
                            </div>
                            <div className="col-6 ps-1 pe-1">
                                <ArrivalTime value={dateTime} onChange={(dateTime, arrivalTime) => {
                                    this.setState({dateTime, arrivalTime});
                                }}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ps-1 pe-1">
                                <Details value={details} onChange={(details) => this.setState({details})}/>
                            </div>
                        </div>

                        <AddJob updateData={(jobs) => {
                            console.log(jobs)
                            this.setState({jobs})
                        }}/>
                    </div>

                    <div>
                        <div className={'divider mb-3'}/>
                        <div className={'d-flex justify-content-between'}>
                            <Button type={'primary'} disable={isLoader}
                                    onHandleClick={this.Invoicing.bind(this)}>Send Invoice</Button>
                            <Button type={'danger'} disable={isLoader}
                                    onHandleClick={this.CancelRequest.bind(this)}>Cancel</Button>
                        </div>
                    </div>
                </>)}
            </>
        );
    }
}
