enum InvoiceStatus {
    new = 'new',
    wait = 'wait',
    hold = 'hold',
    error = 'error',
    success = 'success',
    refund = 'refund',
    cancel = 'cancel',
}

export default InvoiceStatus;
