import React, { useState } from 'react';
import InfoDetails from './info-details';
import { getIcon, getIconService, getServiceName, getStatus } from '../utils';
import StoreAssistance from '../store/assistance';
import IconChevronTop from '../icons/chevron-top.svg';
import IconChevronBottom from '../icons/chevron-bottom.svg';

interface IProps {
    application: any
    toggleMap: () => void
    positionTo: any
}

const ApplicationDetails = (props: IProps) => {
    const { application, toggleMap, positionTo } = props;
    const [isDetailsOpen, setDetailsOpen] = useState<boolean>(true);

    return (
        <div className={'header-container'}>
            <div className={'title-mobile'}>
                <img className={'service-icon'}
                     src={getIconService(application?.request?.service)}
                     alt={'service-icon'}
                />
                {getServiceName(application?.request?.service)}
            </div>
            <div style={{display: isDetailsOpen ? 'block' : 'none'}}>
                <div className={'divider'} />
                <div style={{marginTop: 15}} className={'info-title'}>Client</div>
                <InfoDetails label={'Full name'} text={`${application?.request?.firstName} ${application?.request?.lastName}`} />
                <InfoDetails label={'Phone'} text={application?.request?.phone} phone={application?.request?.phone} />
                <div style={{marginTop: 15}} className={'info-title'}>Vehicle</div>
                <InfoDetails label={'Number'} text={application?.request?.carNumber} />
                <InfoDetails label={'Brand and model'} text={`${application?.request?.carBrand} ${application?.request?.carModel}`} />
                <InfoDetails label={'Body Number (VIN)'} text={application?.request?.carVin} />
                <InfoDetails label={'Color'} text={application?.request?.carColor} />
                <InfoDetails onClick={toggleMap} label={'Location'} text={'Google maps'} />
                {StoreAssistance.request?.typeObject.addressTo && (
                    <InfoDetails
                        label={'Address to'}
                        text={StoreAssistance.request?.typeObject.addressTo}
                        link={`http://www.google.com/maps/place/${positionTo.lat},${positionTo.lng}`}
                    />
                )}
            </div>
            <div className={'header'}>
                <div className={'text'}>
                    {getStatus(StoreAssistance.request?.status)}
                    <img className={'icon-status'}
                         src={getIcon(StoreAssistance.request?.status)}
                         alt={'icon'}
                    />
                </div>
                <div
                    className={'text yellow-text'}
                    onClick={() => setDetailsOpen(!isDetailsOpen)}
                >
                    Details
                    <img className={'icon-status'}
                        alt={'chevron'}
                        src={isDetailsOpen ? IconChevronTop : IconChevronBottom}
                    />
                </div>
            </div>
        </div>
    );
};

export default ApplicationDetails;
