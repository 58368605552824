import {database, firestore, functions} from './firebase';
import {action, observable, reaction} from 'mobx';
import SHA1 from 'crypto-js/sha1';
import EncUtf8 from 'crypto-js/enc-utf8';
import EncBase64 from 'crypto-js/enc-base64';
import round from 'lodash/round';
import mergeWith from 'lodash/mergeWith';
import sortBy from 'lodash/sortBy';

import {IHistory, IJob} from './interface';
import ServiceType from "./interface/enums/ServiceType";
import Firebase from "firebase";
import {TypeStatus} from "./interface/enums";
import IMessage from "./interface/IMessage";

// configure({enforceActions: 'observed'});

let stopListenerRequests = () => {};
let stopListenerRequestMessages = () => {};

// const PRIVATE_KEY = 'web-key';
// const PUBLIC_KEY = 'express-t-web-key';

interface IInvoicing {
    arrivalTime: number,
    details: string,
    jobs: Array<IJob>,
    totalPay: number,
    applicationId: string,
}

interface ICommission {
    amount: number,
    currency: string,
    percent: number
    type: ServiceType
}

interface ICommissionCompany {
    amount: number,
    percent: number,
    amountForTotal: number,
}

class StoreAssistance {
    @observable public PUBLIC_KEY: string = '';
    @observable public messages: Array<any> = [];
    @observable public loaded: boolean = false;
    @observable public request: IHistory | null = null;
    @observable public messagesUnread: number = 0;
    @observable public CURRENCY: string = '';
    @observable public COMPANY_NAME: string = '';

    public uid: string = '';
    public id: string = '';
    public app: string = '';
    public vehicleId: string = '';
    public PRIVATE_KEY: string = '';
    public COMMISSIONS: Array<ICommission> = [];
    public COMMISSION_COMPANY: ICommissionCompany = {
        amount: 0,
        percent: 0,
        amountForTotal: 0,
    };
    public name: string = '';

    constructor() {
        reaction(() => this.request?.typeObject.nameRSA, async () => {
            await this.updateKeys(this.request?.typeObject.nameRSA);

            if(this.request?.status !== TypeStatus.CANCELED) {
                const messaging = Firebase.messaging();

                messaging.getToken({
                    vapidKey: process.env.REACT_APP_PUSH_KEY_PAIR,
                }).then(async (currentToken) => {
                    if (currentToken) {
                        console.log('currentToken', currentToken);
                        await this.savePushNotificationsToken(currentToken);
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });

                messaging.onMessage((payload) => {
                    console.log('[firebase-messaging-sw.js] Received background message ', payload);
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/logo192.png',
                        sound: './sms.mp3',
                    };

                    new Notification(payload.notification.title, notificationOptions);
                });
            }

            if(this.request?.typeObject.chatId) {
                this.subscribeRequestMessages(this.request?.typeObject.request.userId as string, this.request?.typeObject.chatId as string);
            }
        })
    }

    @action
    MutationRequest(data: IHistory | null) {
        console.log('StoreAssistance.MutationRequest data:', data);
        this.request = data;
    }

    @action
    MutationRequestMessages(data: Array<IMessage> = []) {
        console.log('StoreAssistance.MutationRequestMessages data:', data);

        this.messages = sortBy(data, 'date').map((item) => ({
            author: item.uid ? this.request?.typeObject.request.firstName : 'me',
            type: 'text',
            data: { text: item.message }
        }));

        this.messagesUnread = data.filter(i => i.uid && !i.read).length;
    }

    @action
    MutationLoader(data: boolean) {
        console.log('StoreAssistance.MutationLoader data:', data);
        this.loaded = data;
    }

    @action
    unsubscribeRequest() {
        console.log(`StoreAssistance.unsubscribeRequest`);
        stopListenerRequests();
    }

    @action
    subscribeRequest(uid: string, id: string, app: string, vehicleId: string) {
        console.log(`StoreAssistance.subscribeRequest uid: ${uid} id: ${id}`);

        stopListenerRequests();

        this.uid = uid;
        this.id = id;
        this.app = app;
        this.vehicleId = vehicleId

        const ref = firestore
            .collection('APP')
            .doc(this.app)
            .collection('vehicles')
            .doc(this.vehicleId)
            .collection('assistance-request-help')
            .doc(id);

        stopListenerRequests = ref.onSnapshot((snapshot) => {
            if (snapshot.exists) {
                this.MutationLoader(true);
                this.MutationRequest(snapshot.data() as IHistory);
            }
        }, (err) => {
            console.warn(`StoreAssistance.subscribeRequest ERROR: ${err}`);
        });
    }

    @action
    subscribeRequestMessages(uid: string, id: string) {
        console.log(`StoreAssistance.subscribeRequestMessages uid: ${uid} id: ${id}`);

        stopListenerRequestMessages();

        const ref = firestore
            .collection('APP')
            .doc(this.app)
            .collection('users')
            .doc(uid)
            .collection('chats')
            .doc(id)
            .collection('messages')

        stopListenerRequestMessages = ref.onSnapshot((snapshots) => {
            if (!snapshots.empty) {
                const messages = snapshots.docs
                    .map(doc => mergeWith(doc.data(), {id: doc.id})) as IMessage[];
                this.MutationRequestMessages(messages);
            } else {

            }
        }, (err) => {
            console.warn(`StoreAssistance.subscribeRequestMessages ERROR: ${err}`);
        });
    }

    async sendMessage(payload: {id: string, message: string, uid: string}) {
        const assistancePackagePay = functions.httpsCallable('Assistance-WebMessageSend');
        return await assistancePackagePay({
            ...payload,
            key: this.PUBLIC_KEY,
        });
    }

    async markAsReadMessages(payload: {id: string, uid: string}) {
        const assistancePackagePay = functions.httpsCallable('Assistance-WebMessageMarkAsRead');
        return await assistancePackagePay({
            ...payload,
            key: this.PUBLIC_KEY,
        });
    }

    async _sendRequest(dataJson: any) {
        if(this.PUBLIC_KEY) {
            const dataString = JSON.stringify(dataJson);
            const dataStringArray = EncUtf8.parse(dataString);
            const payloadString = EncBase64.stringify(dataStringArray);

            const hash = SHA1(this.PRIVATE_KEY + payloadString + this.PRIVATE_KEY);
            const signature = hash.toString(EncBase64);

            const assistancePackagePay = functions.httpsCallable('Assistance-Callback');
            return await assistancePackagePay({
                signature,
                payload: payloadString,
                key: this.PUBLIC_KEY,
                app: this.app,
            });
        }
    }

    @action
    async CallToCustomer(id: string) {
        try {
            console.log('StoreAssistance.CallToCustomer id:', id);

            const result = await this._sendRequest({
                action: "call",
                orderId: this.id,
                userId: this.uid,
                id: id,
            })

            console.log('StoreAssistance.CallToCustomer result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.CallToCustomer ERROR: ', e);
        }
    }

    @action
    async CancellationBeforePaymentRequest(details: string) {
        try {
            console.log('StoreAssistance.CancellationBeforePaymentRequest details:', details);

            const result = await this._sendRequest({
                action: "cancel",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
                details,
            });

            console.log('StoreAssistance.CancellationBeforePaymentRequest result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.CancellationBeforePaymentRequest ERROR: ', e);
        }
    }

    @action
    async Invoicing(payload: IInvoicing) {
        try {
            console.log('StoreAssistance.Invoicing payload:', payload);

            const result = await this._sendRequest({
                action: "invoice",
                orderId: this.id,
                userId: this.uid,
                id: payload.applicationId,
                arrivalTime: payload.arrivalTime,
                details: payload.details,
                jobs: payload.jobs,
                totalPay: payload.totalPay,
            });
            console.log('StoreAssistance.Invoicing result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.Invoicing ERROR: ', e);
        }
    }

    @action
    async AdditionalInvoicing(payload: IInvoicing) {
        try {
            console.log('StoreAssistance.AdditionalInvoicing payload:', payload);

            const result = await this._sendRequest({
                action: "additional-invoice",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
                arrivalTime: payload.arrivalTime,
                details: payload.details,
                jobs: payload.jobs,
                totalPay: payload.totalPay,
            });
            console.log('StoreAssistance.AdditionalInvoicing result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.AdditionalInvoicing ERROR: ', e);
        }
    }

    @action
    async UpdateInfoRequest(arrivalTime: number, details: string) {
        try {
            console.log('StoreAssistance.UpdateInfoRequest details:', details);

            const result = await this._sendRequest({
                action: "update-info",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
                arrivalTime: arrivalTime,
                details: details,
            });

            console.log('StoreAssistance.UpdateInfoRequest result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.UpdateInfoRequest ERROR: ', e);
        }
    }

    @action
    async HelpingProcessRequest() {
        try {
            console.log('StoreAssistance.HelpingProcessRequest');

            const result = await this._sendRequest({
                action: "helping-process",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
            });

            console.log('StoreAssistance.HelpingProcessRequest result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.HelpingProcessRequest ERROR: ', e);
        }
    }

    @action
    async SuccessServiceRequest(details: string) {
        try {
            console.log('StoreAssistance.SuccessServiceRequest');

            const result = await this._sendRequest({
                action: "success-service",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
                details: details,
            });

            console.log('StoreAssistance.SuccessServiceRequest result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.SuccessServiceRequest ERROR: ', e);
        }
    }

    @action
    async FakeRequest(payload: IInvoicing) {
        try {
            console.log('StoreAssistance.FakeRequest');

            const result = await this._sendRequest({
                action: "fake-request",
                orderId: this.id,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
                arrivalTime: payload.arrivalTime,
                details: payload.details,
                jobs: payload.jobs,
                totalPay: payload.totalPay,
            });

            console.log('StoreAssistance.FakeRequest result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.FakeRequest ERROR: ', e);
        }
    }

    @action
    async updateKeys(nameRSA: string | undefined) {
        if (nameRSA) {
            const configRef = database.ref(`services/assistance/partners/${nameRSA}`);
            const configSnapshot: any = await configRef.once('value');
            const config = configSnapshot.val();

            console.log('updateKeys', config);

            this.PRIVATE_KEY = config.privateKey;
            this.PUBLIC_KEY = config.publicKey;
            this.COMMISSIONS = config.commission || [];
            this.COMMISSION_COMPANY = config.commissionCompany || {
                amount: 0,
                percent: 0,
                amountForTotal: 0,
            };
            this.COMPANY_NAME = config.name;
            this.CURRENCY = config.payment.currency;

            // this.COMMISSIONS = config.з || [];
        }
    }

    // buildPrice(payload: {type: ServiceType, amount: number}) {
    //     const commission = this.COMMISSIONS.find(i=> i.type === payload.type);
    //
    //     if(commission) {
    //         const amount = (payload.amount * (this.COMMISSION_COMPANY.percent + 1));
    //
    //         const result = (amount * (commission.percent + 1) + commission.amount);
    //
    //         return round(result, 2);
    //     }
    //
    //     return payload.amount;
    // }
    //
    // buildPriceCompany(payload: {amount: number}) {
    //     const commission = this.COMMISSION_COMPANY;
    //     if(commission) {
    //         const result = (payload.amount + commission.amount);
    //
    //         return round(result, 2);
    //     }
    //
    //     return payload.amount;
    // }

    jobPriceForCompany(payload: { type: ServiceType, amount: number }) {
        const {amount, percent} = this.COMMISSION_COMPANY;
        const result = payload.amount * (percent + 1) + amount;
        return round(result, 2);
    }

    jobPriceTotal(payload: {type: ServiceType, amount: number}) {
        const amount = this.jobPriceForCompany(payload);

        const commissionJob = this.COMMISSIONS.find(i=> i.type === payload.type);
        const commissionOther = this.COMMISSIONS.find(i=> i.type === ServiceType.OTHER);
        const commission = commissionJob || commissionOther;

        if(commission) {
            const result = (amount * (commission.percent + 1) + commission.amount);
            return round(result, 2);
        }

        return payload.amount;
    }

    totalPriceForCompany(payload: {amount: number}) {
        const {amountForTotal} = this.COMMISSION_COMPANY;
        const result = payload.amount + amountForTotal;
        return round(result, 2);

        // 150 грн куда девать
    }

    totalPrice(payload: {amount: number}, isAdditional = false) {
        const commission = this.COMMISSIONS.find(i=> i.type === ServiceType.OTHER);
        if(commission && !isAdditional) {
            const {amountForTotal} = this.COMMISSION_COMPANY;
            const result = payload.amount + (amountForTotal * (commission.percent + 1));
            return round(result, 2);
        }
        return payload.amount
    }

    @action async savePushNotificationsToken(tokenFCM: string) {
        try {
            console.log('StoreAssistance.savePushNotificationsToken');

            const result = await this._sendRequest({
                action: "push-notifications-token-save",
                tokenFCM,
                userId: this.uid,
                id: this.request?.typeObject.request.id,
            });

            console.log('StoreAssistance.savePushNotificationsToken result:', result);
            return result;
        } catch (e) {
            console.log('StoreAssistance.savePushNotificationsToken ERROR: ', e);
        }
    }

    // push-notifications-token-save

    // getPrice(type: ServiceType) {
    //     const commission = this.COMMISSIONS.find(i=> i.type === type);
    //
    //     if(commission) {
    //         const result = (payload.amount * (commission.percent + 1) + commission.amount);
    //
    //         return round(result, 2);
    //     }
    //
    //     return payload.amount;
    // }

    // reactionNameRsa = reaction(() => this.request?.typeObject.nameRSA, this.updateKeys.bind(this))
}

export default new StoreAssistance();
