enum TypeHistory {
    ASSISTANCE = 'assistance',
    INSURANCE = 'insurance',
    INFO = 'info',
    PENALTY = 'penalty',
    ASSISTANCE_PACKAGE = 'assistance-package',
    INSURANCE_ASSISTANCE_PACKAGE = 'insurance:assistance-package',
}

export default TypeHistory;
