import React from "react";

interface IProps {
    onHandleClick?: () => Promise<void>,
    type: 'primary' | 'warning' | 'danger' | 'success',
    disable?: boolean
    className?: string
}

interface IState {
    loader: boolean,
}

export default class Button extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loader: false,
        }
    }

    async onClick() {
        if (!this.state.loader) {
            try {
                this.setState({loader: true})
                // await new Promise((resolve: any, reject) => {
                //     setTimeout(() => {
                //         resolve();
                //     }, 3000)
                // })
                if(this.props.onHandleClick) {
                    await this.props.onHandleClick();
                }
            } catch (e) {
                console.log(e);
            }

            this.setState({loader: false})
        }
    }

    render() {
        const {disable = false, type, className = '', children} = this.props;

        return (
            <button
                onClick={this.onClick.bind(this)}
                className={`btn btn-${type} ${className}`}
                type="button"
                disabled={this.state.loader || disable}>

                {this.state.loader ?
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        <span className={'ps-2'}>Loading...</span>
                    </> : children
                }
            </button>
        );
    }
}
