import Firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import "firebase/firestore";
import "firebase/database";
import "firebase/messaging";

Firebase.initializeApp({
    projectId: process.env.REACT_APP_PROJECT_ID,
    locationId: process.env.REACT_APP_LOCATION_ID,
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export const functions = Firebase.app().functions('europe-west1');
export const firestore = Firebase.app().firestore();
export const database = Firebase.app().database();
// functions.useEmulator("localhost", 5001);
firestore.settings({experimentalForceLongPolling: true});
