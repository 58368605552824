import InvoiceStatus from '../store/interface/enums/InvoiceStatus';

export const getPaymentStatus = (status: InvoiceStatus | undefined) => {
    switch (status) {
        case InvoiceStatus.new:
            return 'Waiting for payment';
        case InvoiceStatus.refund:
            return 'Refund';
        case InvoiceStatus.hold:
            return 'Locked';
        case InvoiceStatus.success:
            return 'Success';
        case InvoiceStatus.error:
            return 'Error';
        case InvoiceStatus.wait:
            return 'Waiting for payment';
        default:
            return '';
    }
}
