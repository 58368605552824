import React from "react";

interface IProps {
    onChange: (value: string) => void,
    value: any
}

interface IState {
}

export default class Details extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div>
                <label htmlFor={`job-details`} className="form-label">Description of the problem*</label>
                <input onChange={(el) => this.props.onChange(el.target.value)}
                       value={this.props.value}
                       type="text"
                       className="form-control"
                       id={`job-details`}
                       aria-describedby={`job-details-help`}
                />
                <div id={`job-details-help`} className="form-text"></div>
            </div>
        );
    }
}
