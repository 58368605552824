import React from "react";
// import StoreAssistance from "../store/assistance";
// import TypeStatus from "../store/interface/enums/TypeStatus";

interface IProps {
    onChange: (value: string) => void,
    value: any
}

interface IState {
}

export default class RequestId extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div>
                <label htmlFor="applicationId" className="form-label">Application number in your system</label>
                <input onChange={(el) => this.props.onChange(el.target.value)}
                       value={this.props.value}
                       type="text"
                       className="form-control"
                       id="applicationId"
                       aria-describedby="applicationIdHelp"
                />
                <div id="applicationIdHelp" className="form-text"></div>
            </div>
        );
    }
}
