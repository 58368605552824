const list = [
    {
        value: 'EVACUATION',
        name: 'Evacuation'
    }, {
        value: 'DELIVERY_FUEL',
        name: 'Delivery fuel'
    }, {
        value: 'WHEEL_REPLACEMENT',
        name: 'Wheel replacement'
    }, {
        value: 'RECHARGE_BATTERY',
        name: 'Recharge battery'
    }, {
        value: 'EMERGENCY_OPENING_CAR',
        name: 'Emergency opening car'
    }, {
        value: 'DEPARTURE_TECHNICAL_ASSISTANCE_WIZARD',
        name: 'Departure technical assistance wizard'
    }, {
        value: 'RENTAL_CAR',
        name: 'Rental car'
    }, {
        value: 'LEGAL_ASSISTANCE',
        name: 'Legal assistance'
    }, {
        value: 'CALL',
        name: 'Call'
    }, {
        value: 'LOCKED_STEERING',
        name: 'Locked steering'
    }, {
        value: 'EXTRACTION_DITCH',
        name: 'Extraction ditch'
    }, {
        value: 'WAITING',
        name: 'Waiting'
    }, {
        value: 'FAKE_REQUEST',
        name: 'Fake request'
    }, {
        value: 'HEAVY_LOADING',
        name: 'Heavy loading'
    }, {
        value: 'ADDITIONAL_WORK',
        name: 'Additional work'
    },
];
export default list;
