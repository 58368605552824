import React from 'react';
import {observer} from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';

import StoreAssistance from '../store/assistance';
import IJob from "../store/interface/IJob";
import {ServiceType, TypeStatus} from "../store/interface/enums";
import Works from "../store/works";

interface IProps {
    updateData: (jobs: Array<IJob>) => void
}

interface IState {
    jobs: Array<IJob>,
    works: Array<{ value: string, name: string }>
}

@observer
export default class AddJob extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            jobs: [],
            works: Works
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {

    }

    async AddJob() {
        await this.setState({
            jobs: [...this.state.jobs, {
                amount: 0,
                value: 0,
                details: '',
                paymentType: 'pay',
                currency: StoreAssistance.CURRENCY,
                type: StoreAssistance.request?.typeObject.request.service || ServiceType.EVACUATION,
            }]
        });

        this.updateData();
    }

    async setFieldJob(value: string | number, index: number, field: string) {
        const cloneJobs = cloneDeep(this.state.jobs);
        cloneJobs[index] = {...cloneJobs[index], [field]: value as ServiceType}

        await this.setState({
            jobs: cloneJobs,
        });

        this.updateData();
    }

    updateData() {
        this.props.updateData(this.state.jobs);
    }

    async RemoveJob(index: number) {
        const array = [...this.state.jobs]; // make a separate copy of the array
        array.splice(index, 1);
        await this.setState({jobs: array});

        this.updateData();
    }

    render() {
        const jobsPrice = this.state.jobs.reduce((result, item) => {
            return result + (item.amount || 0);
        }, 0)

        const jobsPriceForCompany = this.state.jobs.reduce((result, item) => {
            const amount = StoreAssistance.jobPriceForCompany({type: item.type as ServiceType, amount: (item.amount || 0) })
            return result + amount;
        }, 0)

        const totalPriceForCompany = StoreAssistance.request?.status === TypeStatus.MASTER_WORKS ? jobsPriceForCompany :
            StoreAssistance.totalPriceForCompany({amount: jobsPriceForCompany});

        const jobsPriceTotal = this.state.jobs.reduce((result, item) => {
            const amount = StoreAssistance.jobPriceTotal({type: item.type as ServiceType, amount: (item.amount || 0) })
            return result + amount;
        }, 0)

        const priceTotal = StoreAssistance.totalPrice({amount: jobsPriceTotal}, StoreAssistance.request?.status === TypeStatus.MASTER_WORKS);


        return (
            <>
                <p className={'sub-title'}>ADDING JOB</p>

                <div className={'job-wrap'}>
                    {this.state.jobs.map((job, jobIndex) => (
                        <form key={`job-${jobIndex}`}>
                            <div className="row">
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-8 ps-1 pe-1">
                                            <label htmlFor={`job-type-${jobIndex}`} className="form-label">Job</label>
                                            <select className="form-select"
                                                    aria-label="Default select example"
                                                    onChange={(el) => {
                                                        this.setFieldJob(el.target.value, jobIndex, 'type');
                                                    }}
                                                    id={`job-type-${jobIndex}`}
                                                    value={job.type}
                                            >
                                                {Works.map((work, workIndex) => (
                                                    <option key={workIndex} value={work.value}>
                                                        {work.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-2 ps-1 pe-1">
                                            <label htmlFor={`job-amount-${jobIndex}`} className="form-label">Price {StoreAssistance.CURRENCY}</label>
                                            <input value={job.amount}
                                                   type="number"
                                                   className="form-control"
                                                   id={`job-amount-${jobIndex}`}
                                                   aria-describedby={`job-amount-${jobIndex}-help`}
                                                   onChange={(el) => {
                                                       this.setFieldJob(parseFloat(el.target.value), jobIndex, 'amount');
                                                   }}
                                            />
                                            {/*<span>{StoreAssistance.buildPrice({*/}
                                            {/*    type: job.type as ServiceType,*/}
                                            {/*    amount: job.amount as number*/}
                                            {/*})}</span>*/}
                                        </div>
                                        <div className="col-2 ps-1 pe-1">
                                            <label htmlFor={`job-value-${jobIndex}`}
                                                   className="form-label">Options</label>
                                            <input value={job.value}
                                                   type="number"
                                                   className="form-control"
                                                   id={`job-value-${jobIndex}`}
                                                   aria-describedby={`job-value-${jobIndex}-help`}
                                                   onChange={(el) => {
                                                       this.setFieldJob(parseFloat(el.target.value), jobIndex, 'value');
                                                   }}
                                            />
                                            {/*<div id={`job-value-${jobIndex}-help`} className="form-text">Километры | литры | другие*/}
                                            {/*    параметры*/}
                                            {/*</div>*/}

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 ps-1 pe-1">

                                            {/*<label htmlFor={`job-details-${jobIndex}`} className="form-label">Адресс</label>*/}
                                            <input value={job.details}
                                                   type="text"
                                                   className="form-control"
                                                   id={`job-details-${jobIndex}`}
                                                   aria-describedby={`job-details-${jobIndex}-help`}
                                                   onChange={(el) => {
                                                       this.setFieldJob(el.target.value, jobIndex, 'details');
                                                   }}
                                            />
                                            <div id={`job-details-${jobIndex}-help`} className="form-text">Address or other information
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1 d-flex align-items-center ps-0 pe-0">
                                    <button type="button"
                                            className="btn btn-danger btn-del"
                                            onClick={this.RemoveJob.bind(this, jobIndex)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>


                        </form>
                    ))}
                </div>

                <div className="row mt-2">
                    <div className="col-sm">
                        <button type="button"
                                className="btn btn-warning"
                                onClick={this.AddJob.bind(this)}
                        >
                            Add jobs
                        </button>
                    </div>
                </div>

                <div className={'to-pay'}>
                    <br/>
                    Amount of services: {jobsPrice} {StoreAssistance.CURRENCY}<br/>
                    Amount with commission: {totalPriceForCompany} {StoreAssistance.CURRENCY}<br/>

                    Total with commission Beep!:
                    <span className={'amount'}> {priceTotal}</span>
                    <span className={'currency'}> {StoreAssistance.CURRENCY}</span>
                </div>

            </>
        );
    }
}
