import React from 'react';

import RequestForm from './RequestForm';
import RequestMobileForm from './request-mobile-form/request-mobile-form';

function App() {
    return (
        <>
            {window.innerWidth < 700 ? <RequestMobileForm /> : <RequestForm/>}
        </>
    );
}

export default App;
