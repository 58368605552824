import { ServiceType } from '../store/interface/enums';
import IconFuel from '../icons/assistance/emergency-fuel.svg';
import IconRecharging from '../icons/assistance/battery-recharge.svg';
import IconChangeWheel from '../icons/assistance/wheel-repair.svg';
import IconCommissioner from '../icons/assistance/accident-support.svg';
import IconAccidentSupport from '../icons/assistance/accident-support.svg';
import IconLockUnlock from '../icons/assistance/door-unlocking.svg';
import IconCall from '../icons/assistance/other-problem.svg';
import IconTowTruck from '../icons/assistance/tow-track.svg';

export const getIconService = (service: ServiceType | undefined): string => {
    switch (service) {
        case ServiceType.DELIVERY_FUEL:
            return IconFuel;
        case ServiceType.RECHARGE_BATTERY:
            return IconRecharging;
        case ServiceType.WHEEL_REPLACEMENT:
            return IconChangeWheel;
        case ServiceType.LEGAL_ASSISTANCE:
            return IconCommissioner;
        case ServiceType.EMERGENCY_OPENING_CAR:
            return IconLockUnlock;
        case ServiceType.DEPARTURE_TECHNICAL_ASSISTANCE_WIZARD:
            return IconAccidentSupport;
        case ServiceType.CALL:
            return IconCall;
        case ServiceType.EVACUATION:
        default:
            return IconTowTruck;
    }
}
