import TypeStatus from '../store/interface/enums/TypeStatus';
import IconDraft from '../icons/contract-statuses/draft.svg';
import IconConfirm from '../icons/contract-statuses/confirm.svg';
import IconWait from '../icons/contract-statuses/wait.svg';
import IconMustEdit from '../icons/contract-statuses/must-edit.svg';
import IconConcluded from '../icons/contract-statuses/concluded.svg';
import IconExpired from '../icons/contract-statuses/expired.svg';

export const getIcon = (status: TypeStatus | undefined): string => {
    switch (status) {
        case TypeStatus.CALL_WAITING:
            return IconDraft;
        case TypeStatus.REQUEST_IN_PROCESS:
            return IconConfirm;
        case TypeStatus.WAITING_FOR_THE_MASTER:
            return IconWait
        case TypeStatus.MASTER_WORKS:
            return IconMustEdit
        case TypeStatus.WAITING_FOR_PAYMENT:
            return IconWait
        case TypeStatus.SUCCESSFULLY:
            return IconConcluded
        case TypeStatus.CANCELED:
            return IconExpired
        default:
            return IconDraft;
    }
}
