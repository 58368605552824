import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { Alert, Modal } from 'bootstrap';
import ModalB from 'react-bootstrap/Modal';

import StoreAssistance from '../../store/assistance';
import AddJob from "../add-job";
import IJob from "../../store/interface/IJob";
import Works from "../../store/works";
import Button from "../button";
import Details from "../details";
import ArrivalTime from "../arrival-time";
import { ServiceType } from "../../store/interface/enums";
import momentTimezone from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import StepWaitingForPayment from '../steps-mobile/step-waiting-for-payment';
import AddJobMobile from '../add-job-mobile';
import FakeCallModal from '../modal';

interface IProps {
    isMobile?: boolean
}

interface IState {
    jobs: Array<IJob>,
    arrivalTime: number,
    details: string,
    works: Array<{ value: string, name: string }>
    dateTime: string,
    isLoader: boolean,
    show: boolean,
    jobFake: IJob,
    errors: Array<string>,
    applicationId: string,
}

@observer
export default class StepMasterWorks extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const arrivalTime = StoreAssistance.request?.typeObject.request.arrivalTime;
        const dateArrival = new Date(Date.now() + 40 * 60000);
        let dateTime = '';

        if (arrivalTime) {
            dateTime = momentTimezone(arrivalTime, 'x').tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm:ss');
        } else {
            dateTime = dateArrival.toJSON().split('.')[0]
        }


        this.state = {
            jobs: [],
            details: StoreAssistance.request?.typeObject.request.details || '',
            arrivalTime: arrivalTime || dateArrival.getTime(),
            dateTime,
            works: Works,
            isLoader: false,
            show: false,
            errors: [],
            jobFake: {
                amount: 200,
                value: 0,
                details: '',
                paymentType: 'pay',
                currency: 'UAH',
                type: ServiceType.FAKE_REQUEST
            },
            applicationId: StoreAssistance.request?.typeObject.request.id || ''
        };
    }

    async SuccessService() {
        try {
            this.setState({isLoader: true});
            await StoreAssistance.SuccessServiceRequest(this.state.details);
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    modal: Modal | null = null;
    alertError: Alert | null = null;

    async FakeRequest() {
        // alert('Сумма ложного вызова не может превыщать сумму заказа');
        const jobs = StoreAssistance.request?.typeObject.jobs || [];
        const paidJobs = jobs.find(item => {
            return ['hold', 'success'].includes(item.status as string)
                && (item.amount as number) >= (this.state.jobFake.amount as number)
        })

        if (!paidJobs) {
            alert('Сумма ложного вызова не может превышать сумму заказа');
            return;
        }

        try {
            this.setState({isLoader: true, show: false});

            await StoreAssistance.FakeRequest({
                arrivalTime: this.state.arrivalTime,
                details: this.state.details,
                jobs: [this.state.jobFake],
                totalPay: this.state.jobFake.amount as number,
                applicationId: this.state.applicationId
            });
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    async onFakeRequest() {
        // if (this.modal) {
        //     this.modal.toggle()
        // }
        this.setState({show: true})

    }

    async AdditionalInvoicing() {
        try {
            if (!this.state.jobs.length) {
                alert('нужно добавить работы')
                return
            }

            if (this.state.jobs.find((item) => !item.amount)) {
                alert('В работе необходимо указать цену')
                return
            }

            if (!this.state.details) {
                alert('нужно добавить описание')
                return
            }

            this.setState({isLoader: true});

            const jobs = cloneDeep(this.state.jobs)

            jobs.forEach((item) => {
                item.amount = StoreAssistance.jobPriceTotal({
                    type: item.type as ServiceType,
                    amount: (item.amount || 0)
                })
            });

            const totalPay = jobs.reduce((result, item) => (result + (item.amount || 0)), 0)

            await StoreAssistance.AdditionalInvoicing({
                arrivalTime: this.state.arrivalTime,
                details: this.state.details,
                jobs,
                totalPay,
                applicationId: this.state.applicationId
            });
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    async handleClose() {
        this.setState({show: false})
    }

    changeFakeAmountValue = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            jobFake: {
                ...this.state.jobFake,
                amount: parseFloat(event.target.value),
            }
        });
    }

    changeFakeDetailsValue = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            jobFake: {
                ...this.state.jobFake,
                details: event.target.value,
            }
        });
    }

    render() {
        const {isMobile} = this.props;
        const { details, dateTime, show, jobFake } = this.state;

        return (
            <div>
                {isMobile ? (
                    <div>
                        <StepWaitingForPayment/>
                        <ArrivalTime
                            onChange={(dateTime: string, arrivalTime: number) => this.setState({dateTime, arrivalTime})}
                            value={dateTime}
                        />
                        <label htmlFor={'details'} className={'label form-label'}>Details</label>
                        <input className={'input'}
                               id={'details'}
                               value={details}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({details: event.target.value})}
                        />
                        <AddJobMobile updateJobList={(jobs: IJob[]) => this.setState({jobs})} />
                        <Button
                            type={'primary'}
                            disable={this.state.isLoader}
                            className={'button'}
                            onHandleClick={this.AdditionalInvoicing.bind(this)}>Send Invoice</Button>
                        <Button
                            type={'danger'}
                            disable={this.state.isLoader}
                            data-bs-toggle="modal"
                            className={'button'}
                            onHandleClick={this.onFakeRequest.bind(this)}>Fake request</Button>
                        <div style={{marginTop: 8}}>
                            <Button
                                type={'success'}
                                disable={this.state.isLoader}
                                className={'button'}
                                onHandleClick={this.SuccessService.bind(this)}>Completed</Button>
                        </div>
                        <FakeCallModal
                            isVisible={show}
                            onClose={() => this.setState({show: false})}
                            amount={jobFake.amount || ''}
                            details={jobFake.details || ''}
                            onChangeAmount={this.changeFakeAmountValue}
                            onChangeDetails={this.changeFakeDetailsValue}
                            confirmModal={this.FakeRequest.bind(this)}
                        />
                    </div>
                ) : (
                    <div className={''}>
                        <div className="row">
                            <div className="col-7 ps-1 pe-1">
                                <Details value={this.state.details} onChange={(details: string) => this.setState({details})}/>
                            </div>
                            <div className="col-5 ps-1 pe-1">
                                <ArrivalTime value={this.state.dateTime} onChange={(dateTime, arrivalTime) => {
                                    this.setState({dateTime, arrivalTime});
                                }}/>
                            </div>
                        </div>

                        <AddJob updateData={(jobs) => this.setState({jobs})}/>

                        <div className={'divider mb-4'}/>

                        <div className="d-flex justify-content-between">
                            <Button type={'success'} disable={this.state.isLoader}
                                    onHandleClick={this.SuccessService.bind(this)}>Completed</Button>
                            <Button type={'warning'} disable={this.state.isLoader}
                                    onHandleClick={this.AdditionalInvoicing.bind(this)}>Send Invoice</Button>
                            <Button type={'danger'} disable={this.state.isLoader} data-bs-toggle="modal"
                                    onHandleClick={this.onFakeRequest.bind(this)}>Fake request</Button>
                        </div>
                        <ModalB show={show} animation={true}>
                            <ModalB.Header>
                                <ModalB.Title><h5 className="title">Fake request</h5></ModalB.Title>
                            </ModalB.Header>
                            <ModalB.Body>
                                <label htmlFor={`job-fake-amount`} className="form-label">
                                    Amount {StoreAssistance.CURRENCY}
                                </label>
                                <input value={jobFake.amount}
                                       type="number"
                                       className="form-control"
                                       id={`job-fake-amount`}
                                       aria-describedby={`job-fake-amount-help`}
                                       onChange={(el) => {
                                           this.setState({
                                               jobFake: {
                                                   ...this.state.jobFake,
                                                   amount: parseFloat(el.target.value)
                                               }
                                           });
                                       }}
                                />
                                <label htmlFor={`job-fake-details`} className="form-label">
                                    Address or other information
                                </label>
                                <input value={jobFake.details}
                                       type="text"
                                       className="form-control"
                                       id={`job-fake-details`}
                                       aria-describedby={`job-fake-details-help`}
                                       onChange={(el) => {
                                           this.setState({
                                               jobFake: {
                                                   ...this.state.jobFake,
                                                   details: el.target.value
                                               }
                                           });
                                       }}
                                />
                            </ModalB.Body>
                            <ModalB.Footer>
                                <button type="button" disabled={this.state.isLoader} className="btn btn-secondary"
                                        onClick={() => this.setState({show: false})}>
                                    Close
                                </button>
                                <Button type={'danger'} disable={this.state.isLoader}
                                        onHandleClick={this.FakeRequest.bind(this)}>Fake request</Button>
                            </ModalB.Footer>
                        </ModalB>
                    </div>
                )}
            </div>
        );
    }
}
