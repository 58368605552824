import React, { ChangeEvent, useState } from 'react';
import { IJob } from '../store/interface';
import IconDelete from '../icons/contract-statuses/delete.svg'
import Works from '../store/works';
import { ServiceType, TypeStatus } from '../store/interface/enums';
import Button from './button';
import StoreAssistance from '../store/assistance';

interface IProps {
    updateJobList: (jobs: IJob[]) => void
}

const AddJobMobile = (props: IProps) => {
    const { updateJobList } = props;
    const [jobList, setJobList] = useState<IJob[]>([]);
    const workStatus: TypeStatus | undefined = StoreAssistance.request?.status;

    const addJobs = async (): Promise<void> => {
        await setJobList([...jobList, {
            amount: 0,
            value: 0,
            details: '',
            paymentType: 'pay',
            currency: StoreAssistance.CURRENCY,
            type: StoreAssistance.request?.typeObject.request.service || ServiceType.EVACUATION,
        }]);
    };

    const changeJob = (value: string, id: number, key: any): void => {
        const newArray: IJob[] = jobList.map((jobItem: {[index: string]: any}, index: number) => {
            if (id === index) {
                jobItem[key] = value as ServiceType
            }
            return jobItem;
        });
        setJobList(newArray);
        updateJobList(newArray);
    }

    const removeJob = (id: number): void => {
        const filteredArray = jobList.filter((i: IJob, index: number) => index !== id);
        setJobList(filteredArray);
        updateJobList(filteredArray);
    };

    return (
        <div>
            {jobList.map((job: IJob, index: number) => {
                return (
                    <div style={{marginTop: 24}} key={index}>
                        <div className={'flex-row'}>
                            <div className={'col info-title'}>{workStatus === TypeStatus.MASTER_WORKS ? 'Adding job' : `job-${index + 1}`}</div>
                            <div onClick={() => removeJob(index)} className={'delete-icon'}>
                                <img src={IconDelete} alt={'delete'}/>
                            </div>
                        </div>
                        <label htmlFor={`job-type-${index}`} className="label">Job</label>
                        <select className="input"
                                aria-label="Default select example"
                                onChange={(el: ChangeEvent<HTMLSelectElement>) => {
                                    changeJob(el.target.value, index, 'type')
                                }}
                                id={`job-type-${index}`}
                                value={job.type}
                        >
                            {Works.map((work: {name: string, value: string}, workIndex: number) => (
                                <option key={workIndex} value={work.value}>
                                    {work.name}
                                </option>
                            ))}
                        </select>
                        <div className={'flex-row'}>
                            <div style={{marginRight: 16}}>
                                <label htmlFor={'price'} className={'label form-label'}>Price {StoreAssistance.CURRENCY}</label>
                                <input className={'input'}
                                       type="number"
                                       id={'price'}
                                       value={job.amount}
                                       onChange={(event: ChangeEvent<HTMLInputElement>) => changeJob(event.target.value, index, 'amount')}
                                />
                            </div>
                            <div>
                                <label htmlFor={'params'} className={'label form-label'}>Options</label>
                                <input className={'input'}
                                       type="number"
                                       id={'params'}
                                       value={job.value}
                                       onChange={(event: ChangeEvent<HTMLInputElement>) => changeJob(event.target.value, index, 'value')}
                                />
                            </div>
                        </div>
                        <label htmlFor={'address'} className={'label form-label'}>Address or other information</label>
                        <input className={'input'}
                               id={'address'}
                               value={job.details}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => changeJob(event.target.value, index, 'details')}
                        />
                    </div>
                );
            })}
            <Button
                type={'warning'}
                onHandleClick={addJobs}
                className={'button'}
            >Add job</Button>
        </div>
    );
};

export default AddJobMobile;
