import React from 'react';

interface IProps {
    label: string
    text: string
    phone?: string
    onClick?: () => void
    link?: string
}

const InfoDetails = (props: IProps) => {
    const {label, text, phone, onClick, link} = props;
    return (
        <div>
            <div className={'label'}>{label}</div>
            {phone ? (
                <a className={'text link'} href={`tel:${phone}`}>{text}</a>
            ) : link ? <a className={'text google-link'} target={'_blank'} href={link} rel="noopener noreferrer">{text} </a>  : (
                <div onClick={onClick} className={onClick ? 'text google-link' : 'text'}>{text}</div>
            )}
        </div>
    );
};

export default InfoDetails;
