import React from 'react';
import { observer } from 'mobx-react';
import { DirectionsRenderer, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import * as querystring from 'querystring';
import isArray from 'lodash/isArray';
// @ts-ignore
import { Launcher } from 'react-chat-window'

import 'bootstrap-icons/font/bootstrap-icons.css';

import StoreAssistance from './store/assistance';
import TypeStatus from './store/interface/enums/TypeStatus';

import StepCallWaiting from "./components/steps/step-call-waiting";
import StepRequestInProcess from "./components/steps/step-request-in-process";
import StepWaitingMaster from "./components/steps/step-waiting-master";
import StepMasterWorks from "./components/steps/step-master-works";
import Works from "./store/works";

import './style/RequestForm.css';
import { ServiceType } from "./store/interface/enums";
import IconLocation from './icons/location.svg';
import { IPayment } from "./store/interface";
import { getIcon, getIconService, getIconStatusPay, getStatus } from './utils';

interface IProps {
}

interface IState {
    messageList: Array<any>,
    uid: string,
    id: string,
    isOpenChat: boolean,
    mapLoad: boolean,
    directions: any,
    app: string,
    vehicleId: string,
}

const containerStyle = {
    width: '100%',
    height: '100%'
};


@observer
export default class RequestForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            uid: '',
            id: '',
            isOpenChat: false,
            directions: null,
            mapLoad: false,
            app: '',
            vehicleId: '',
            messageList: [{
                author: 'them',
                type: 'text',
                data: {
                    text: 'some text'
                }
            },
                {
                    author: 'me',
                    type: 'emoji',
                    data: {
                        code: 'someCode'
                    }
                },
                {
                    author: 'me',
                    type: 'file',
                    data: {
                        url: 'somefile.mp3',
                        fileName: 'Any old name'
                    }
                }]
        }
    }

    componentDidUpdate() {
        console.log('componentDidUpdate', StoreAssistance.loaded)

        if (StoreAssistance.loaded && this.state.mapLoad && !this.state.directions) {
            console.log('BUILD ROUTE')
            // BUILD ROUTE
            const {latitude = 0, longitude = 0} = StoreAssistance.request?.typeObject.location || {};
            const {
                latitude: latitudeTo = 0,
                longitude: longitudeTo = 0
            } = StoreAssistance.request?.typeObject?.locationTo || {};
            const position = {lat: latitude, lng: longitude};
            const positionTo = {lat: latitudeTo, lng: longitudeTo};

                const waypoints: Array<any> = [{
                    location: {...position},
                    stopover: true
                }, {
                    location: {...positionTo},
                    stopover: true
                }];

                const origin = waypoints.shift().location;
                const destination = waypoints.pop().location;

                // @ts-ignore
                const directionsService = new window.google.maps.DirectionsService();

                directionsService.route(
                    {
                        origin: origin,
                        destination: destination,
                        // @ts-ignore
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        waypoints: waypoints
                    },
                    (result: any, status: any) => {
                        // @ts-ignore
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            console.log('directionsService', result);

                            this.setState({
                                directions: result
                            });
                        }
                    }
                );
        }
    }

    componentDidMount() {
        const params = querystring.parse(window.location.hash.replace('#', '')) as {
            uid: string,
            id: string,
            app: string
            vehicleId: string
            key?: string,
        };

        this.setState({
            uid: params.uid,
            id: params.id,
            app: params.app,
            vehicleId: params.vehicleId,
        })
        console.log('params', params)
        StoreAssistance.subscribeRequest(params.uid, params.id, params.app, params.vehicleId);
    }

    getContent() {
        switch (StoreAssistance.request?.status) {
            case TypeStatus.CALL_WAITING:
                return (<StepCallWaiting/>);
            case TypeStatus.REQUEST_IN_PROCESS:
                return (<StepRequestInProcess/>);
            case TypeStatus.WAITING_FOR_THE_MASTER:
                return (<StepWaitingMaster/>)
            case TypeStatus.MASTER_WORKS:
                return (<StepMasterWorks/>)

            case TypeStatus.WAITING_FOR_PAYMENT:
            case TypeStatus.SUCCESSFULLY:
            case TypeStatus.CANCELED:
            default:
                return null;
        }
    }

    getNameService(service: ServiceType | undefined) {
        const work = Works.find((item) => item.value.toLowerCase() === service?.toLowerCase());
        return work?.name || '';
    }

    async handleNewMessage(message: any) {
        console.log(message);

        await StoreAssistance.sendMessage({
            uid: this.state.uid,
            id: this.state.id,
            message: message.data?.text,
        });
    }

    async handleOpenChat() {
        this.setState({isOpenChat: !this.state.isOpenChat});

        await StoreAssistance.markAsReadMessages({
            uid: this.state.uid,
            id: this.state.id,
        });
    }

    render() {
        console.log('Request forms render');

        const request = StoreAssistance.request?.typeObject.request;
        const jobs = StoreAssistance.request?.typeObject.jobs || [];
        const {latitude = 0, longitude = 0} = StoreAssistance.request?.typeObject.location || {};
        const {
            latitude: latitudeTo = 0,
            longitude: longitudeTo = 0
        } = StoreAssistance.request?.typeObject?.locationTo || {};
        const position = {lat: latitude, lng: longitude};
        const positionTo = {lat: latitudeTo, lng: longitudeTo};


        let totalAmount = 0;
        if (StoreAssistance.request?.typeObject.payment) {
            if (isArray(StoreAssistance.request?.typeObject.payment)) {
                totalAmount = StoreAssistance.request?.typeObject.payment.reduce((result: number, item: IPayment) => {
                    return result + item.amount
                }, 0)
            } else {
                totalAmount = StoreAssistance.request?.typeObject.payment.amount;
            }
        }

        const isEnabledStatus = StoreAssistance.request?.typeObject.chatId && [
            TypeStatus.WAITING_FOR_PAYMENT,
            TypeStatus.MASTER_WORKS,
            TypeStatus.WAITING_FOR_THE_MASTER,
            TypeStatus.SUCCESSFULLY
        ].includes(StoreAssistance.request?.status)

        console.log('process.env.REACT_APP_API_KEY', process.env.REACT_APP_API_KEY)

        return (
            <div className={'MapsContainer'}>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_API_KEY as string}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{...position, lng: position.lng - 0.003}}
                        zoom={17}
                        onLoad={() => {
                            // const bounds = new window.google.maps.LatLngBounds();
                            // map.fitBounds(bounds);
                            this.setState({
                                mapLoad: true
                            })
                        }}
                        onUnmount={() => {
                            // do your stuff before map is unmounted
                        }}
                    >
                        <Marker position={position}
                                icon={{
                                    url: IconLocation,
                                    scaledSize: {width: 100, height: 100},
                                    anchor: {x: 50, y: 50},
                                }}
                                animation={2}
                        />;

                        {this.state.directions ?
                            <DirectionsRenderer directions={this.state.directions}/> : null
                        }

                        <div className={'RequestForm'}>
                            {request ?
                                <div className="card">
                                    <div className="card-header">
                                        <div className={'title'}>
                                            <img className={'icon-assistance'}
                                                 src={getIconService(StoreAssistance.request?.typeObject.request.service)}
                                                 alt={'service-icon'}
                                            />
                                            {this.getNameService(request.service)}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className={'divider'}/>
                                        <div className={'field-data'}>
                                            <label>Company name</label>
                                            <span>{StoreAssistance.COMPANY_NAME}</span>
                                        </div>
                                        <h6 className={'sub-title'}>Client</h6>

                                        <div className={'field-data'}>
                                            <label>Full name</label>
                                            <span>{request.firstName} {request.middleName} {request.lastName}</span>
                                        </div>
                                        <div className={'field-data'}>
                                            <label>Phone number</label>
                                            <span>{request.phone}</span>
                                        </div>
                                        <h6 className={'sub-title'}>Vehicle</h6>
                                        <div className={'field-data'}>
                                            <label>Car number</label>
                                            <span>{request.carNumber || '-'}</span>
                                        </div>
                                        <div className={'field-data'}>
                                            <label>Brand and model</label>
                                            <span> {request.carBrand} {request.carModel} {request.carYear}</span>
                                        </div>
                                        <div className={'field-data'}>
                                            <label>Body Number (VIN)</label>
                                            <span>{request.carVin}</span>
                                        </div>

                                        {
                                            request.carColor ?
                                                <div className={'field-data'}>
                                                    <label>Color</label>
                                                    <span>{request.carColor}</span>
                                                </div>
                                                : null
                                        }

                                        {/*<div className={'field-data'}>*/}
                                        {/*    <label>Местоположение</label>*/}
                                        {/*    <span>*/}
                                        {/*        <a href={`http://www.google.com/maps/place/${position.lat},${position.lng}`}*/}
                                        {/*           target={'_blank'}>*/}
                                        {/*            Google Maps*/}
                                        {/*        </a>*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}

                                        <div className={'field-data'}>
                                            <label>Address from</label>
                                            <span>
                                                <a href={`http://www.google.com/maps/place/${position.lat},${position.lng}`}
                                                   target={'_blank'}
                                                   rel="noopener noreferrer"
                                                >
                                                    {StoreAssistance.request?.typeObject.address}
                                                </a>
                                            </span>
                                        </div>

                                        {StoreAssistance.request?.typeObject.addressTo ?
                                            <div className={'field-data'}>
                                                <label>Address to</label>
                                                <span>
                                                <a href={`http://www.google.com/maps/place/${positionTo.lat},${positionTo.lng}`}
                                                   target={'_blank'}
                                                   rel="noopener noreferrer"
                                                >
                                                    {StoreAssistance.request?.typeObject.addressTo}
                                                </a>
                                            </span>
                                            </div> : null
                                        }

                                        {
                                            totalAmount ?
                                                <div className={'field-data'}>
                                                    <label>Amount</label>
                                                    <span>{totalAmount} {StoreAssistance.CURRENCY}</span>
                                                </div>
                                                : null
                                        }


                                        <div className={'sub-title pt-3'}>
                                            {getStatus(StoreAssistance.request?.status)}
                                            <img className={'icon-status'}
                                                 src={getIcon(StoreAssistance.request?.status)}
                                                 alt={'icon-status'}
                                            />
                                        </div>

                                        {StoreAssistance.request?.typeObject?.userRating ?
                                            <div className={'sub-title pt-3'}>
                                                Feedback {StoreAssistance.request?.typeObject?.userRating} / 10
                                            </div> : null
                                        }
                                    </div>
                                </div> : null
                            }

                            <div className={'content'}>
                                {jobs.length ?
                                    <div className="jobs">
                                        <div className={'title'}>
                                            Jobs list
                                        </div>
                                        <div className="row jobs-header">
                                            <div className="col-6 field-data">
                                                <label>Type</label>
                                            </div>
                                            <div className="col-2  field-data">
                                                <label>Amount</label>
                                            </div>
                                            <div className="col-2  field-data">
                                                <label>Params</label>
                                            </div>
                                            <div className="col-2  field-data">
                                                <label>Payment</label>
                                            </div>
                                        </div>

                                        {
                                            jobs.map((job, jobIndex) => (
                                                <div className={''} key={`user-job-${jobIndex}`}>
                                                    <div className="row">
                                                        <div className="col-6 field-data">
                                                        <span>
                                                            <img className={'icon-assistance'}
                                                                 src={getIconService(job.type)}
                                                                 alt={'icon-assistance'}
                                                            />
                                                            {Works.find(item => item.value === job.type)?.name || '-'}
                                                        </span>
                                                        </div>
                                                        <div className="col-2  field-data">
                                                            <span>{job.amount} {StoreAssistance.CURRENCY}</span>
                                                        </div>
                                                        <div className="col-2  field-data">
                                                            <span>{job.value || ' - '}</span>
                                                        </div>
                                                        <div className="col-2  field-data">
                                                        <span>
                                                            <img className={'icon-status'}
                                                                 src={getIconStatusPay(job.status)}
                                                                 alt={'icon-status'}
                                                            />
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {job.details ?
                                                            <div className="col-12 field-data">
                                                                <label>{job.details}</label>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div> : null
                                }

                                {this.getContent()}
                            </div>

                            {/*<Widget title="Beep! Partner"*/}
                            {/*        subtitle="And my cool subtitle"*/}
                            {/*        handleNewUserMessage={this.handleNewMessage.bind(this)}*/}
                            {/*/>*/}

                            {isEnabledStatus ?
                                <Launcher
                                    agentProfile={{
                                        teamName: request?.firstName,
                                        imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                                    }}
                                    onMessageWasSent={this.handleNewMessage.bind(this)}
                                    messageList={StoreAssistance.messages}
                                    showEmoji
                                    newMessagesCount={StoreAssistance.messagesUnread}
                                    handleClick={this.handleOpenChat.bind(this)}
                                    isOpen={this.state.isOpenChat}
                                /> : null
                            }

                            {/*<div className="alert alert-warning alert-dismissible fade show" role="alert">*/}
                            {/*    A simple warning alert with <a href="#" className="alert-link">an example link</a>. Give it a click*/}
                            {/*    if you like.*/}
                            {/*    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>*/}
                            {/*</div>*/}

                        </div>
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    }
}
