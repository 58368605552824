enum ServiceType {
    /**
     * EMERGENCY_OPENING_CAR - Emergency opening of the car
     * DEPARTURE_TECHNICAL_ASSISTANCE_WIZARD - Departure of the technical assistance wizard
     * DELIVERY_FUEL - Delivery of fuel
     * EVACUATION - Evacuation
     * WHEEL_REPLACEMENT - Wheel replacement
     * RECHARGE_BATTERY - Recharge the battery
     * RENTAL_CAR - Rental car
     * LEGAL_ASSISTANCE - Legal assistance
     * CALL
     */
    EMERGENCY_OPENING_CAR = 'EMERGENCY_OPENING_CAR',
    DEPARTURE_TECHNICAL_ASSISTANCE_WIZARD = 'DEPARTURE_TECHNICAL_ASSISTANCE_WIZARD',
    DELIVERY_FUEL = 'DELIVERY_FUEL',
    EVACUATION = 'EVACUATION',
    WHEEL_REPLACEMENT = 'WHEEL_REPLACEMENT',
    RECHARGE_BATTERY = 'RECHARGE_BATTERY',
    RENTAL_CAR = 'RENTAL_CAR',
    LEGAL_ASSISTANCE = 'LEGAL_ASSISTANCE',
    CALL = 'CALL',
    FAKE_REQUEST = 'FAKE_REQUEST',
    OTHER = 'OTHER'
}

export default ServiceType;
