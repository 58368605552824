import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DirectionsRenderer, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import '../style/RequestMobileForm.css';
import StoreAssistance from '../store/assistance';
import querystring from 'querystring';
import ApplicationDetails from '../components/application-details';
import TypeStatus from '../store/interface/enums/TypeStatus';
import StepCallWaiting from '../components/steps/step-call-waiting';
import StepRequestInProcess from '../components/steps/step-request-in-process';
import StepWaitingMaster from '../components/steps/step-waiting-master';
import StepWaitingForPayment from '../components/steps-mobile/step-waiting-for-payment';
import StepMasterWorks from '../components/steps/step-master-works';
import IconBack from '../icons/back.svg';

import IconLocation from '../icons/location.svg';

interface IState {
    uid: string,
    id: string,
    isMapLoad: boolean,
    directions: any,
    app: string,
    vehicleId: string,
}

const RequestMobileForm = () => {
    const application: any = StoreAssistance.request?.typeObject;
    const params = querystring.parse(window.location.hash.replace('#', '')) as {
        uid: string,
        id: string,
        app: string,
        vehicleId: string,
        key?: string,
    };

    const [state, setState] = useState<IState>({
        isMapLoad: false,
        directions: null,
        uid: params.uid,
        id: params.id,
        app: params.app,
        vehicleId: params.vehicleId,
    });

    const [isMapVisible, setMapVisible] = useState<boolean>(false);
    const {latitude = 0, longitude = 0} = StoreAssistance.request?.typeObject.location || {};
    const position = {lat: latitude, lng: longitude};
    const {
        latitude: latitudeTo = 0,
        longitude: longitudeTo = 0
    } = StoreAssistance.request?.typeObject?.locationTo || {};
    const positionTo = {lat: latitudeTo, lng: longitudeTo};

    useEffect(() => {
        if(params.id) {
            StoreAssistance.subscribeRequest(params.uid, params.id, params.app, params.vehicleId);
        } else {
            StoreAssistance.unsubscribeRequest();
        }

        return StoreAssistance.unsubscribeRequest;
    }, [params.id, params.app, params.uid, params.vehicleId]);

    useEffect(() => {
        if (StoreAssistance.loaded && state.isMapLoad && !state.directions) {
            const {latitude = 0, longitude = 0} = StoreAssistance.request?.typeObject.location || {};
            const {
                latitude: latitudeTo = 0,
                longitude: longitudeTo = 0
            } = StoreAssistance.request?.typeObject?.locationTo || {};
            const position = {lat: latitude, lng: longitude};
            const positionTo = {lat: latitudeTo, lng: longitudeTo};

            const waypoints: Array<any> = [{
                location: {...position},
                stopover: true
            }, {
                location: {...positionTo},
                stopover: true
            }];

            const origin = waypoints.shift().location;
            const destination = waypoints.pop().location;

            // @ts-ignore
            const directionsService = new window.google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    // @ts-ignore
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result: any, status: any) => {
                    // @ts-ignore
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        console.log('directionsService', result);

                        setState({
                            ...state,
                            directions: result,
                        });
                    }
                }
            );
        }
    }, [state.isMapLoad]);

    const toggleMap = (): void => {
        setMapVisible(!isMapVisible);
    };

    const getContent = (): JSX.Element | null => {
        switch (StoreAssistance.request?.status) {
            case TypeStatus.CALL_WAITING:
                return <StepCallWaiting isMobile/>;
            case TypeStatus.REQUEST_IN_PROCESS:
                return <StepRequestInProcess isMobile />;
            case TypeStatus.WAITING_FOR_THE_MASTER:
                return <StepWaitingMaster isMobile />;
            case TypeStatus.MASTER_WORKS:
                return <StepMasterWorks isMobile />;
            case TypeStatus.WAITING_FOR_PAYMENT:
            case TypeStatus.SUCCESSFULLY:
            case TypeStatus.CANCELED:
            default:
                return <StepWaitingForPayment/>;
        }
    }

    return (
        <>
            {isMapVisible ? (
                <div className={'MapsContainer'}>
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY as string}>
                        <GoogleMap
                            mapContainerStyle={{
                                    width: '100%',
                                    height: '100%'
                            }}
                            center={{...position, lng: position.lng}}
                            zoom={17}
                            onLoad={() => setState({...state, isMapLoad: true})}
                            onUnmount={() => setState({...state, isMapLoad: false, directions: null})}
                        >
                            <div className={'map-container'} onClick={toggleMap}>
                                <img className={'icon-mr'} src={IconBack} alt={'chevron'}/>
                                Back to details
                            </div>
                            <Marker position={position}
                                    icon={{
                                        url: IconLocation,
                                        scaledSize: {width: 100, height: 100},
                                        anchor: {x: 50, y: 50},
                                    }}
                                    animation={2}
                            />;

                            {state.directions && (
                                <DirectionsRenderer directions={state.directions}/>
                            )}

                        </GoogleMap>
                    </LoadScript>
                </div>
            ): (
                <div className={'main-container container p-0'}>
                    <ApplicationDetails application={application} toggleMap={toggleMap} positionTo={positionTo} />
                    <div className={'button-wrapper'}>
                        {getContent()}
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(RequestMobileForm);
