import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import StoreAssistance from '../../store/assistance';
import Button from "../button";
import Details from "../details";
import ArrivalTime from "../arrival-time";
import momentTimezone from "moment-timezone";
import StepWaitingForPayment from '../steps-mobile/step-waiting-for-payment';

interface IProps {
    isMobile?: boolean
}

interface IState {
    arrivalTime: number,
    details: string,
    dateTime: string,
    isLoader: boolean,
}

@observer
export default class StepWaitingMaster extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const arrivalTime = StoreAssistance.request?.typeObject.request.arrivalTime;
        const dateArrival = new Date(Date.now() + 40*60000);
        let dateTime = '';

        if(arrivalTime) {
            // dateTime = new Date(arrivalTime).toJSON().split('.')[0]
            // debugger
            dateTime = momentTimezone(arrivalTime, 'x').tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm:ss');

        } else {
            dateTime = dateArrival.toJSON().split('.')[0]
        }

        console.log(dateTime);

        this.state = {
            details: StoreAssistance.request?.typeObject.request.details || '',
            arrivalTime: arrivalTime || dateArrival.getTime(),
            dateTime,
            isLoader: false,
        };
    }

    async UpdateInfo() {
        try {
            this.setState({isLoader: true});
            await StoreAssistance.UpdateInfoRequest(this.state.arrivalTime, this.state.details);
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    async HelpingProcess() {
        try {
            this.setState({isLoader: true});
            await StoreAssistance.HelpingProcessRequest();
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    onChangeDate = (dateTime: string, arrivalTime: number): void => {
        this.setState({dateTime, arrivalTime});
    }

    render() {
        const { isMobile } = this.props;
        const { details, dateTime, isLoader} = this.state;

        return (
            <>
                {isMobile ? (
                    <div>
                        <StepWaitingForPayment/>
                        <div>
                            <label htmlFor={'description'} className={'label form-label'}>Description</label>
                            <input className={'input'}
                                   id={'description'}
                                   value={details}
                                   onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({details: event.target.value})}
                            />
                            <ArrivalTime
                                onChange={this.onChangeDate}
                                value={dateTime}
                                isMobile
                            />
                            <Button
                                disable={isLoader}
                                type={'primary'}
                                onHandleClick={this.UpdateInfo.bind(this)}
                                className={'button'}
                            >Update information</Button>
                        </div>
                        <Button
                            disable={isLoader}
                            type={'success'}
                            onHandleClick={this.HelpingProcess.bind(this)}
                            className={'button'}
                        >Master Arrived</Button>
                    </div>
                ) : (
                    <div className={''}>
                        <div className="row">
                            <div className="col-7 ps-1 pe-1">
                                <Details value={this.state.details} onChange={(details) => this.setState({details})}/>
                            </div>
                            <div className="col-5 ps-1 pe-1">
                                <ArrivalTime value={this.state.dateTime} onChange={(dateTime, arrivalTime) => {
                                    this.setState({dateTime, arrivalTime});}}/>
                            </div>
                        </div>

                        <div className={'divider mb-4'} />

                        <div className="d-flex justify-content-between">
                            <Button type={'primary'} disable={this.state.isLoader}
                                    onHandleClick={this.UpdateInfo.bind(this)}>Update information</Button>
                            <Button type={'success'} disable={this.state.isLoader}
                                    onHandleClick={this.HelpingProcess.bind(this)}>Master Arrived</Button>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
