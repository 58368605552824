import InvoiceStatus from '../store/interface/enums/InvoiceStatus';
import IconWait from '../icons/contract-statuses/wait.svg';
import IconMustEdit from '../icons/contract-statuses/must-edit.svg';
import IconConcluded from '../icons/contract-statuses/concluded.svg';
import IconExpired from '../icons/contract-statuses/expired.svg';

export const getIconStatusPay = (status: InvoiceStatus | undefined) => {
    switch (status) {
        case InvoiceStatus.new:
            return IconWait;
        case InvoiceStatus.refund:
            return IconMustEdit;
        case InvoiceStatus.hold:
            return IconConcluded;
        case InvoiceStatus.success:
            return IconConcluded;
        case InvoiceStatus.cancel:
        case InvoiceStatus.error:
            return IconExpired;
        case InvoiceStatus.wait:
            return IconWait;
        default:
            return '';
    }
};
