enum TypeStatus {
    CALL_WAITING = 'Call waiting',
    REQUEST_IN_PROCESS = 'Request in process',
    WAITING_FOR_PAYMENT = 'Waiting for payment',
    CANCELED = 'Canceled',
    WAITING_FOR_THE_MASTER = 'Waiting for the master',
    MASTER_WORKS = 'Master works',
    SUCCESSFULLY = 'Successfully',
    IN_PROCESS = 'In process',
    NEW = 'New',
}

export default TypeStatus;
