import React from 'react';
import { observer } from 'mobx-react';
import StoreAssistance from '../../store/assistance';
import { getIconService, getIconStatusPay, getPaymentStatus, getServiceName } from '../../utils';
import { IJob } from '../../store/interface';

const StepWaitingForPayment = () => {
    const application: any = StoreAssistance.request?.typeObject;

    return (
        <div style={{marginTop: 24}}>
            <div className={'info-title'}>Jobs list</div>
            {application?.jobs.map((jobItem: IJob, index: number) => {
                const { amount, details, type, status } = jobItem;
                return (
                    <div className={'job-container'} key={index}>
                        <div style={{marginTop: 24}} className={'flex-row justify-space-btw'}>
                            <div className={'icon-container'}>
                                <img src={getIconService(type)} alt={'service-icon'}/>
                            </div>
                            <div style={{flex: 1, marginLeft: 12}}>
                                <div className={'status-text'}>{getServiceName(type)}</div>
                                <div style={{marginTop: 4}} className={'flex-row'}>
                                    <img src={getIconStatusPay(status)}
                                         alt={'icon'}
                                    />
                                    <div style={{marginLeft: 10}} className={'status-text'}>
                                        {getPaymentStatus(status)}
                                    </div>
                                </div>
                            </div>
                            <div className={'price'}>{amount} {StoreAssistance.CURRENCY}</div>
                        </div>
                        <div style={{marginLeft: 44, marginTop: 8}} className={'details'}>{details}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default observer(StepWaitingForPayment);
