import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import StoreAssistance from '../../store/assistance';
import Button from "../button";
import RequestId from "../request-id";

interface IProps {
    isMobile?: boolean
}

interface IState {
    applicationId: string,
    details: string,
    isLoader: boolean,
}

@observer
export default class StepCallWaiting extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            applicationId: '',
            details: '',
            isLoader: false,
        };
    }

    async CallToCustomer() {
        try {
            this.setState({isLoader: true});
            await StoreAssistance.CallToCustomer(this.state.applicationId);
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});

        // var alertList = document.querySelectorAll('.alert')
        // alertList.forEach(function (alert) {
        //     new Alert(alert)
        // })
    }

    async CancelRequest() {
        try {
            this.setState({isLoader: true});
            await StoreAssistance.CancellationBeforePaymentRequest(this.state.details);
        } catch (e) {
            console.log(e);
        }

        this.setState({isLoader: false});
    }

    render() {
        const request = StoreAssistance.request?.typeObject.request;
        const {isMobile} = this.props;
        const { applicationId, isLoader } = this.state;

        return (
            <>
                {isMobile ? (
                    <div>
                        <label htmlFor={'applicationId'} className={'label form-label'}>Application number in your system</label>
                        <input className={'input'}
                               id={'applicationId'}
                               value={applicationId || request?.id}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({applicationId: event.target.value})}
                        />
                        <Button
                            type={'primary'}
                            disable={isLoader}
                            onHandleClick={this.CallToCustomer.bind(this)}
                            className={'button'}
                        >Call to Client</Button>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-6 ps-1 pe-1">
                                <RequestId
                                    value={applicationId || request?.id || ''}
                                    onChange={(applicationId: string) => this.setState({applicationId})}
                                />
                            </div>
                        </div>

                        <div>
                            <div className={'divider mb-3'}/>
                            <div className="d-flex justify-content-between">
                                <Button type={'primary'} disable={this.state.isLoader}
                                        onHandleClick={this.CallToCustomer.bind(this)}>Call to Client</Button>
                                {/*<Button style={'danger'} disable={this.state.isLoader}*/}
                                {/*        onHandleClick={this.CancelRequest.bind(this)}>Отменить</Button>*/}
                            </div>
                        </div>
                    </>)}
            </>
        );
    }
}
